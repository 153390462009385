import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Ultralyd from "../../components/Ultralyd.js"
import Knap from "../../components/Knap.js"


const UltralydPage = () => (
    <LayoutComponent>
        <h1>Ultralyd</h1>
        <p>I samråd med vores leverandører har vi udvidet vores leveringskompetence til også at inkludere ultralydstilbehør i form af træningsfantomer, kvalitetskontrol (QA) og vævssimulation</p>
        <h1>Vores leverandører</h1>
        <Ultralyd />
        <Knap />
    </LayoutComponent>
)
export default UltralydPage